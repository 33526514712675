import { commonValidators, onlyDigits } from '../../../utils'
import { WrappedInput, WrappedInputProps } from '../Input'

type ComponentProps = Omit<WrappedInputProps, 'name'> & { name?: WrappedInputProps['name'] }

export const OkatoInput = ({
    className,
    label,
    name,
    placeholder,
    ...restProps
}: ComponentProps) => {
    return (
        <WrappedInput
            {...restProps}
            className={className ?? 'formComponentItem'}
            label={label ?? 'ОКАТО'}
            name={name ?? 'okato'}
            placeholder={placeholder}
            rules={[{ validator: commonValidators.minMax(8, 11) }]}
            maxLength={11}
            minLength={8}
            normalize={onlyDigits}
        />
    )
}
